<template>
  <div>
    <div class="block block1">
      <div class="text-center my-3">
        <h4>หนังสือสัญญาเช่าอุปกรณ์ถ่ายภาพ</h4>
      </div>
      <div class="d-flex justify-content-between">
        <div class="mb-2">
          สัญญาฉบับนี้ทำขึ้นระหว่าง : บริษัท คาเมร่า มัคคิอาโต จำกัด <br />
          เลขประจำตัวผู้เสียภาษี : 0105561181776 <br />
          ที่อยู่ : 294/1 ห้อง 12 ซี ชั้น 12 อาคารเอเชีย ถนนพญาไท <br />
          แขวงถนนเพชรบุรี เขตราชเทวี กรุงเทพมหานคร <br />
          ซึ่งต่อไปนี้เรียกว่า "ผู้ให้เช่า" ฝ่ายหนึ่ง กับ
        </div>
        <div class="text-right">
          <h4>เลขที่สัญญาเช่า : {{ info.order_id }}</h4>
          เขียนที่ ร้าน Lenslineup ชั้น 12 อาคารเอเชีย ราชเทวี กรุงเทพ
          <br />
          วันที่ {{ thai_date(info.start_date) }}
          <div v-if="info.member.retro_username">
            <small>[{{ info.member.retro_username }}]</small>
          </div>
          <div v-else-if="info.member.gen_username">
            <small>[{{ info.member.gen_username }}]</small>
          </div>
        </div>
      </div>
      <div class="mb-1">
        คุณ
        <b>
          <u> {{ info.member.first_name }} {{ info.member.last_name }} </u>
        </b>
        เลขที่บัตรประชาชน
        <b
          ><u>{{ info.member.card_id }}</u></b
        >
        <br />
        ที่อยู่ บ้านเลขที่
        <b>
          <u>{{ info.member.address1 }} </u>
        </b>
        <span v-if="info.member.road">
          ถนน
          <b>
            <u>
              {{ info.member.road ? info.member.road.replace("ถนน", "") : "" }}
            </u>
          </b>
        </span>
        <span v-if="info.member.province === 'กรุงเทพมหานคร'">
          <span>
            แขวง
            <b>
              <u>{{ info.member.district }} </u>
            </b>
          </span>
          <span>
            เขต
            <b>
              <u>{{ info.member.amphoe }}</u>
            </b>
          </span>
          <br />
        </span>
        <span v-else>
          <span>
            ตำบล
            <b>
              <u>{{ info.member.district }} </u>
            </b>
          </span>
          <span>
            อำเภอ
            <b>
              <u>{{ info.member.amphoe }}</u>
            </b>
          </span>
          <br />
        </span>
        จังหวัด
        <b>
          <u>{{ info.member.province }}</u></b
        >
        รหัสไปรษณีย์
        <b>
          <u>{{ info.member.zipcode }}</u></b
        >
        <br />
        เบอร์โทรศัพท์มือถือ
        <b>
          <u> {{ info.member.phone }}</u>
        </b>
        <br />
        ซึ่งต่อไปนี้เรียกว่า "ผู้เช่า" อีกฝ่ายหนึ่ง
        โดยคู่สัญญาทั้งสองฝ่ายตกลงทำสัญญาโดยมีข้อความดังต่อไปนี้
      </div>
      <div class="mb-1">
        ข้อ 1. ผู้เช่าได้เช่าทรัพย์สิน (อุปกรณ์ถ่ายภาพ) รายการดังนี้
      </div>
    </div>
    <div v-if="shouldBreak(1)" class="page-break"></div>
    <div class="block block2" ref="block2">
      <div class="mb-1">
        <table class="my-table">
          <thead>
            <tr>
              <th style="width: 3%; size: 12px">ลำดับ</th>
              <th style="font-size: 12px">รายการ</th>
              <th style="font-size: 12px">หมายเลขอุปกรณ์</th>
              <th style="font-size: 12px">ค่าเช่าวันละ <small>(บาท)</small></th>
              <th style="font-size: 12px">
                ค่าเช่าลดแล้ว <small>(บาท)</small>
              </th>
              <th style="font-size: 12px">
                ราคารับผิดชอบ <small>(บาท)</small>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in info.booking" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                <div style="font-size: 16px; margin-bottom: 5px">
                  {{ item.product.lease_name }}
                </div>

                <div
                  class="accessories_include"
                  v-if="item.product.accessories_include.length"
                >
                  อุปกรณ์เสริมที่ให้ไปด้วย
                </div>
                <div
                  class="ml-1 accessories_include"
                  v-for="x in item.product.accessories_include"
                  :key="x._id"
                >
                  - {{ x.name }} (ราคา {{ x.lease_price | number }}฿)
                </div>
              </td>
              <td>
                <pre style="background: none; color: black"
                  >{{ formatText(item.product_sub.serial, 20) }}
                  </pre
                >

                <!-- {{ item.product_sub.serial | limitText }} -->
              </td>
              <td>{{ item.product.rental_price | number }}</td>
              <td>{{ item.price | number }}</td>
              <td>{{ item.product.lease_price | number }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="shouldBreak(2)" class="page-break"></div>
      <div class="mb-2">
        ข้อ 2. ผู้เช่าตกลงชำระค่าเช่าให้แก่ผู้ให้เช่าเป็นรายวัน
        ตามรายละเอียดในข้อ 1 โดยมีกำหนดระยะเวลาเช่านับแต่ <br />
        วันที่ ____{{ thai_format.start_date }}____ เวลา ___{{
          thai_format.start_time
        }}น.___ และมีกำหนดสิ้นสุดระยะเวลาการเช่าในวันที่ ____{{
          thai_format.end_date
        }}____ เวลา __{{ thai_format.end_time }}น.__ อุปกรณ์ทั้งหมด __{{
          table_items.length
        }}__ รายการ ค่าเช่าวันละ __{{ info.full_price_per_day | number }}__
        รวมระยะเวลาเป็นทั้งหมด __{{ info.duration }}__ วัน
        คิดเป็นเงินค่าเช่าทั้งหมด __{{ info.full_price_lease | number }}__ บาท
        ส่วนลด __{{ info.sum_discount | number }}__ บาท

        <span v-if="info.shipping_price > 0">
          ค่าส่งอุปกรณ์ ____ {{ info.shipping_price | number }} ____ บาท
        </span>
        รวมทั้งหมดหลังหักส่วนลด ___{{ info.total_net_price | number }}___ บาท
      </div>

      <b-row>
        <b-col col="6">
          <b-card class="text-center mb-1">
            เงินค่าประกันทรัพย์สินการเช่ารวมทั้งหมด <br /><br />
            _________________________________________________________ บาท <br />
            <br />
            ในรูปแบบ ▢ เงินสด ▢ โอนเข้าบัญชี ▢ วงเงินบัตรเครดิต
            <hr style="border-color: rgb(49, 49, 49); margin-top: 25px" />
            ข้อมูลบัญชีคืนเงินค่าประกันทรัพย์สินการเช่า <br />
            <hr style="border-color: rgb(49, 49, 49); margin-top: 30px" />
            <hr style="border-color: rgb(49, 49, 49); margin-top: 35px" />
          </b-card>
        </b-col>
        <b-col col="6">
          <b-card class="text-center mb-1">
            <h4>
              รวมเงินค่าเช่าที่ต้องชำระทั้งหมด
              {{ info.total_net_price | number }} บาท
            </h4>
          </b-card>
          <b-card class="text-center">
            <h4>
              กำหนดคืนอุปกรณ์ในวันที่ <br />
              <b
                >{{ thai_format.end_date }} ก่อนเวลา
                {{ thai_format.end_time }} น.
              </b>
            </h4>
          </b-card>

          <div class="text-center">
            <br />
            ลงชื่อ___________________________________________________ผู้เช่า
            <br />
            <br />

            (_________________________________________________________)
          </div>
        </b-col>
      </b-row>

      <div v-if="shouldBreak(14)" class="page-break"></div>
      <b-row align-v="center" class="mb-2">
        <b-col> </b-col>
        <b-col> </b-col>
      </b-row>
    </div>
    <div v-if="shouldBreak(3)" class="page-break"></div>
    <div class="block block3">
      <b-row class="mb-2">
        <b-col>
          ข้อ 3. ผู้เช่าได้ตรวจดูทรัพย์สินที่เช่าแล้ว
          เห็นว่าทุกสิ่งอยู่ในสภาพเรียบร้อยใช้การได้อย่างสมบูรณ์จะดูแลทรัพย์สินที่เช่ามิได้ให้สูญหาย
          และบำรุงรักษาให้อยู่ในสภาพดีอยู่เสมอพร้อมที่จะส่งมอบคืนตามสภาพเดิมทุกประการ
          ผู้เช่ารับรองว่า ในระหว่างที่ผู้เช่ารับทรัพย์สิน
          ที่เช่าไปถ้าทรัพย์สินที่เช่าชำรุดหรือเสียหายด้วยประการใดๆ
          และไม่คำนึงว่าจะเป็นความผิดของผู้เช่าหรือไม่ผู้เช่ายินดีชำระค่าปรับ
          ไม่เกิน ราคารับผิดชอบรวม
          <span v-if="total_lease_price > 0"
            >{{ total_lease_price | number }} บาท</span
          >ให้แก่ผู้ให้เช่า
        </b-col>
      </b-row>
    </div>
    <div v-if="shouldBreak(4)" class="page-break"></div>
    <div class="block block4">
      <b-row class="mb-1">
        <b-col>
          ข้อ 4. ผู้เช่าไม่มีสิทธินำทรัพย์สินที่เช่าออกให้ผู้อื่นเช่าช่วง
          หรือทำนิติกรรมใดๆตลอดจนทำให้ก่อและ/หรือเกิดภาระผูกพันใดๆ กับผู้อื่น
          เช่น นำไปจำนำ ขายฝาก ขายต่อจำหน่ายโอนด้วยประการใดๆ
          หรือนำไปเป็นหลักประกันและ/หรือค้ำประกัน
          ในกรณีที่ผู้เช่าฝ่าฝืนผู้เช่าตกลงเสียค่าปรับสูงสุดไม่เกิน 5
          เท่าของราคารับผิดชอบและค่าเช่าตามรายการที่ระบุไว้ในข้อที่ 1
          พร้อมเบี้ยปรับดังที่ระบุไว้ในข้อ 7
          ตามจำนวนวันที่ผู้ให้เช่าได้รับทรัพย์สินที่ให้เช่าคืน
          และผู้ให้เช่ามีสิทธิเรียกและ/ หรือยึดทรัพย์สินที่เช่าคืนได้ทันที
          รวมถึงมีสิทธิริบเงินค่าเช่าที่ชำระแล้วทั้งหมด
          และประกันทรัพย์สินการเช่าทั้งหมด
        </b-col>
      </b-row>
    </div>
    <div v-if="shouldBreak(5)" class="page-break"></div>
    <div class="block block5">
      <b-row class="mb-1">
        <b-col>
          ข้อ 5.
          ผู้เช่าตกลงชำระค่าประกันทรัพย์สินการเช่าโดยหากหลังจากที่ผู้ให้เช่าได้ตรวจสอบทรัพย์สินที่เช่าแล้วปรากฏว่าไม่มีความเสียหายหรือชำรุดบกพร่อง
          ผู้ให้เช่าตกลงคืนเงินค่าประกันดังกล่าวแก่ผู้เช่าเต็มจำนวน
          ทั้งนี้ผู้ให้เช่าจะคืนเงินประกันดังกล่าวหลังจากที่ได้รับและตรวจทรัพย์สินการเช่า
          โดยมีกำหนดระยะเวลาไม่เกิน 1 วัน (24 ชม.)
          นับจากวันที่ผู้ให้เช่าได้รับทรัพย์สินที่เช่าคืน
        </b-col>
      </b-row>

      <!-- <b-row class="mb-1">
        <b-col>
          <b-card>
            <h4 class="mb-1">
              ข้อ 5. ผู้เช่าได้นำหลักประกันจำนวน
              __________________________________________________________________________
              บาท
            </h4>
            <h4>
              ในรูปแบบ ▢ เงินสด ▢ โอน ▢ วงเงินบัตรเครดิต ▢ อื่นๆ
              ________________________________________________
            </h4>
            มามอบให้ผู้ให้เช่ายึดถือไว้เป็นประกันโดยผู้ให้เช่าจะคืนทรัพย์ที่วางประกันให้แก่ผู้เช่าก็ต่อเมื่อผู้ให้เช่าได้รับทรัพย์สินที่เช่าคืนและได้ตรวจสอบแล้วว่าถูกต้อง
            สมบูรณ์ ครบถ้วน และ ไม่มี การชำรุดเสียหายอย่างใดๆ
          </b-card>
        </b-col>
      </b-row> -->
    </div>

    <div v-if="shouldBreak(6)" class="page-break"></div>
    <div class="block block6">
      ข้อ 6. กรณีผู้เช่าประสงค์จะเช่าทรัพย์สินต่อไปอีก
      ผู้เช่าจะต้องแจ้งล่วงหน้าต่อผู้ให้เช่า ก่อน 1 วัน (24 ชม.)
      ที่จะครบกำหนดระยะเวลาการเช่า
      และอุปกรณ์นั้นผู้ให้เช่าอนุญาติให้เช่าต่อได้ผู้เช่าต้องชำระเงินค่าเช่าล่วงหน้าแก่ผู้ให้เช่าก่อนจึงจะมีผลสมบูรณ์ในการขอต่อระยะเวลาการเช่าทรัพย์สิน
      กรณี ผู้เช่าไม่แจ้งล่วงหน้าและ/หรือ
      ไม่ชำระเงินการเช่าล่วงหน้าแก่ผู้ให้เช่าภายในระยะเวลาที่กำหนด
      และผู้ให้เช่าตกลงให้บุคคลอื่นเช่าทรัพย์สินดังกล่าวต่อแล้ว
      จนเป็นเหตุให้บุคคลอื่นไม่ได้เช่าทรัพย์สินดังกล่าวต่อจากผู้เช่า
      ผู้เช่าตกลงชำระค่าปรับแก่ผู้ให้เช่าเป็นจำนวน 10
      เท่าของเงินค่าเช่าล่วงหน้าที่ต้องชำระแก่ผู้ให้เช่า

      <!-- <div class="mb-2">
        ข้อ 7. กรณีที่ผู้เช่าไม่นำทรัพย์สินที่เช่ามาคืน
        ตามวันและเวลาที่ระบุไว้ในสัญญาข้อ 2 โดยไม่ได้แจ้งให้ผู้เช่าทราบล่วงหน้า
        หรือ อุปกรณ์ที่นำมาคืนชำรุด เสียหาย
        ทำให้ผู้ให้เช่าไม่สามารถนำอุปกรณ์นั้นมาให้เช่าต่อได้ ผู้ให้เช่าสามารถ
        ยกเลิกส่วนลดจากโปรโมชั่นทั้งหมด ในข้อ 2 และ
        ผู้เช่าจะตกลงจะชำระค่าเช่าและเบี้ยปรับไม่เกินวันละ
        {{ (info.full_price_per_day * 5) | number }} บาท
        ให้แก่ผู้ให้เช่า
        ตามจำนวนวันที่จนกว่าผู้ให้เช่าจะได้รับทรัพย์สินที่ให้เช่าในสภาพสมบูรณ์
        ครบถ้วน
      </div> -->
      <!-- วันละ 650 และเบี้ยปรับไม่เกินวันละ 1950 บาท -->
    </div>
    <div v-if="shouldBreak(7)" class="page-break"></div>
    <div class="block7">
      <div class="mb-2">
        ข้อ 7. กรณีอุปกรณ์ได้รับความเสียหาย หรือ อุปกรณ์ที่นำมาคืนชำรุด
        หรือสภาพอุปกรณ์ไม่สมบูรณ์เหมือนก่อนที่ผู้เช่ารับอุปกรณ์
        ทำให้ผู้ให้เช่าไม่สามารถนำอุปกรณ์นั้นมาให้เช่าต่อ
        ผู้เช่ายินดีชำระค่าเสียโอกาสทางธุรกิจในการให้เช่าอุปกรณ์เป็นรายวัน
        ไม่เกินวันละ 30% ของค่าเช่าต่อวัน
        จนกว่าผู้ให้เช่าจะได้รับอุปกรณ์คืนในสภาพสมบูรณ์
      </div>
    </div>
    <div v-if="shouldBreak(8)" class="page-break"></div>
    <div class="block8">
      <div class="mb-2">
        ข้อ 8. กรณีที่ผู้เช่าไม่สามารถนำอุปกรณ์ตามข้อที่ 1
        มาคืนได้ภายในวันเวลาที่กำหนดในข้อที่ 2
        และอุปกรณ์นั้นมีการทำสัญญาระหว่างผู้ให้เช่ากับ
        ผู้เช่ารายอื่นต่อจากผู้เช่า
        ผู้เช่ายินดีชำระค่าใช้จ่าย/ส่วนต่าง/ค่าเช่า/เบี้ยปรับ ทั้งหมด
        กรณีเกิดการยกเลิกเปลี่ยนแปลง อุปกรณ์จากผู้เช่ารายอื่นต่อจากผู้เช่า
      </div>
    </div>
    <div v-if="shouldBreak(9)" class="page-break"></div>
    <div class="block9">
      <div class="mb-2">
        ข้อ 9. กรณีที่อุปกรณ์สูญหาย หรือถูกลักทรัพย์ไป
        หรือเหตุอื่นใดทั้งสิ้นที่ผู้เช่าไม่สามารถนำทรัพย์สินที่เช่าส่งมอบคืนแก่ผู้ให้เช่าได้
        โดยผู้เช่าต้องแจ้งให้ผู้ให้เช่าทราบภายใน 1 ชั่วโมงนับแต่ทราบเรื่อง
        ทั้งนี้ผู้เช่าต้องรับผิดชดใช้เงินค่าเสียหายตามราคาทรัพย์สินที่เช่าให้แก่ผู้ให้เช่าทั้งสิ้น
        พร้อมดอกเบี้ยในอัตราร้อยละ ๗.๕ ต่อปี
        นับแต่วันที่ทรัพย์สินที่เช่าสูญหาย/หรือถูกลักทรัพย์ไป
        หรือเหตุอื่นใดทั้งสิ้นที่ผู้เช่าไม่สามารถนำทรัพย์สินที่เช่าส่งมอบคืนแก่ผู้ให้เช่าได้
        เนื่องจากทรัพย์สินดังกล่าวผู้เช่ามีหน้าที่ดูแลรักษาไม่ได้สูญหายหรือถูกลักไป
        และรวมถึงเหตุอื่นใดทั้งสิ้นที่ผู้เช่าไม่สามารถนำทรัพย์สินที่เช่าส่งมอบคืนแก่ผู้ให้เช่าได้
        อนึ่ง
        ผู้เช่ายินยอมรับผิดชอบค่าใช้จ่ายในการดำเนินคดีทั้งทางแพ่งและทางอาญาที่เกิดขึ้น
        เนื่องจากทรัพย์สินที่เช่าสูญหายหรือถูกลักทรัพย์ หรือ
        เหตุอื่นใดทั้งสิ้นที่ผู้เช่าไม่สามารถนำทรัพย์สินที่เช่าส่งมอบคืนแก่ผู้ให้เช่าได้

        <!-- ข้อ 10. เมื่อผู้เช่ากระทำผิดสัญญาไม่นำของมาคืนตามเวลาที่กำหนด
        ไม่ว่าด้วยประการใดๆผู้เช่ายินยอมให้ผู้ให้เช่าดำเนินคดีอาญา
        ในข้อหายักยอกทรัพย์และรับผิดชอบค่าใช้จ่ายใดๆก็ตามที่เกิดขึ้นในระหว่างนั้นจนกว่าจะได้ข้อยุติในคดี -->
      </div>
    </div>
    <div v-if="shouldBreak(10)" class="page-break"></div>
    <div class="block10">
      <div class="mb-2">
        ข้อ 10.
        กรณีคืนอุปกรณ์ก่อนวันสิ้นสุดสัญญาผู้ให้เช่าขอสงวนสิทธิ์ในการคืนเงินค่าเช่าทุกกรณี
      </div>
    </div>
    <div v-if="shouldBreak(11)" class="page-break"></div>
    <div class="block11">
      <div class="mb-2">
        ข้อ 11.
        ผู้ให้เช่าจะไม่รับผิดชอบใดๆในกรณีหากทรัพย์สินการเช่าส่งผลกระทบต่องานของผู้เช่า
      </div>
    </div>
    <div v-if="shouldBreak(12)" class="page-break"></div>
    <div class="block12">
      <div class="mb-2">
        ข้อ 12. การกระทำใดก็ตามของผู้เช่าซึ่งเป็นการฝ่าฝืนข้อตกลงฉบับนี้
        ถือว่าเป็นการผิดสัญญาต่อผู้ให้เช่า
        โดยผู้ให้เช่ามีสิทธิริบเงินประกันทรัพย์สินทั้งหมดได้ทันที
        พร้อมเรียกค่าเสียหาย และ เบี้ยปรับเพิ่มเติมได้
      </div>
    </div>
    <div v-if="shouldBreak(13)" class="page-break"></div>
    <div class="block13">
      <div class="mb-2">
        ข้อ 13. ผู้เช่าและผู้ให้เช่า
        ได้อ่านและเข้าใจข้อความตามสัญญาฉบับนี้นี้ตลอดแล้วเพื่อเป็นหลักฐานจึงได้ลงลายมือชื่อให้ไว้เป็นสำคัญ
      </div>
    </div>
    <div v-if="shouldBreak(14)" class="page-break"></div>
    <div class="block14">
      <b-row align-v="end">
        <b-col>
          <div class="text-center">
            <br /><br /><br />
            ลงชื่อ
            <img
              src="@/assets/images/admin/jsig-2.png"
              alt=""
              width="200px"
              class="pr-1"
            />
            ผู้ให้เช่า <br />
            ( บริษัท คาเมร่า มัคคิอาโต จำกัด )
          </div>
        </b-col>
        <b-col>
          <div class="text-center">
            ลงชื่อ___________________________________________________ผู้เช่า
            <br /><br />

            (_________________________________________________________)
            <br /><br />

            โทรศัพท์_______________________________________________________
            <br />
            <br />
            <span>เลขที่สัญญาเช่า : {{ info.order_id }}<br /></span>
          </div>
        </b-col>
      </b-row>
      <b-row align-v="end">
        <b-col>
          <div class="text-center">
            <br /><br /><br />
            ลงชื่อ_______________________พยาน <br />
            <br />

            (_____________________________________________)
          </div>
        </b-col>
        <b-col>
          <div class="text-center">
            ลงชื่อ______________________________พยาน <br />
            <br />

            (_____________________________________________)
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import "moment-timezone";
import {
  BTable,
  BCard,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
export default {
  components: {
    BTable,
    BCard,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    total_lease_price() {
      if (Array.isArray(this.table_items) && this.table_items.length) {
        return this.table_items.reduce((accumulator, item) => {
          // Check if the item has a 'product' object and a 'lease_price' value
          if (item.product && typeof item.product.lease_price === "number") {
            return accumulator + item.product.lease_price;
          }
          return accumulator;
        }, 0);
      } else {
        // Return 0 if this.booking is not an array or is empty
        return 0;
      }
    },
    generatedText() {
      let text = "";
      for (let i = 1; i <= this.numberOfLines; i++) {
        text += `Line ${i}<br>`; // Using <br> for HTML line breaks
      }
      return text;
    },
  },
  data() {
    return {
      info: {
        _id: null,
        member: {},
      },
      table_items: [],
      thai_format: {
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
      },
      breakAt: null,
      numberOfLines: 20,
      cutManual: false,
    };
  },
  mounted() {
    document.body.classList.remove("dark-layout");
    // this.checkBlockHeight();
  },
  created() {
    this.get_order();
  },
  watch: {
    "$route.query.cut": {
      immediate: true,
      handler() {
        this.updateBreakAtFromQuery();
      },
    },
  },
  methods: {
    formatText(text, maxLineLength) {
      let result = "";
      for (let i = 0; i < text.length; i += maxLineLength) {
        result += text.substring(i, i + maxLineLength) + "\n";
      }
      return result;
    },
    updateBreakAtFromQuery() {
      const cutValue = this.$route.query.cut;
      if (cutValue) {
        console.log("manual cut");
        this.cutManual = true;
        this.breakAt = parseInt(cutValue);
      }
    },
    shouldBreak(loc) {
      return loc === this.breakAt;
    },
    async get_order() {
      await this.$http({
        method: "GET",
        url: `/order/print/${this.$route.params.id}`,
        // url: `/order/print/656a298f87eb94f94e75eaae`,
      })
        .then((x) => {
          const a = x.data.data;
          this.info = a;
          this.table_items = a.booking;
          this.thai_format.start_date = new Date(
            a.start_date
          ).toLocaleDateString("th-TH", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          this.thai_format.end_date = new Date(a.end_date).toLocaleDateString(
            "th-TH",
            { year: "numeric", month: "long", day: "numeric" }
          );
          this.thai_format.start_time = moment(a.start_date).format("HH:mm");
          this.thai_format.end_time = moment(a.end_date).format("HH:mm");

          this.$nextTick(() => {
            if (!this.cutManual) {
              this.checkBlockHeight();
            }
          });
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    checkBlockHeight() {
      const block2 = this.$refs.block2;
      const hh = block2.offsetHeight;
      console.log("block.offsetHeight", hh);
      if (hh >= 200 && hh < 400) {
        this.breakAt = 10;
      } else if (hh >= 400 && hh < 500) {
        this.breakAt = 9;
      } else if (hh >= 500 && hh < 570) {
        this.breakAt = 8;
      } else if (hh >= 570 && hh < 650) {
        this.breakAt = 7;
      } else if (hh >= 650 && hh < 720) {
        this.breakAt = 6;
      } else if (hh >= 720 && hh < 830) {
        this.breakAt = 5;
      } else if (hh >= 830 && hh < 920) {
        this.breakAt = 4;
      } else if (hh >= 920 && hh < 1000) {
        this.breakAt = 3;
      } else if (hh >= 1000 && hh < 1100) {
        this.breakAt = 14;
      } else if (hh > 1100) {
        this.breakAt = 100;
      }
      // if (hh >= 0 && hh <= 200) {
      //   this.breakAt = 12;
      // } else if (hh > 200 && hh <= 400) {
      //   this.breakAt = 10;
      // } else if (hh > 400 && hh < 800) {
      //   this.breakAt = 9;
      // } else if (hh > 800 && hh < 1000) {
      //   this.breakAt = 4;
      // }
      console.log("block.breakAt", this.breakAt);

      //   if (hh < 900) {
      //     this.breakAt = 7;
      //   } else if (hh >= 900 && hh <= 1200) {
      //     this.breakAt = 6;
      //   }
    },

    thai_date(date) {
      if (date) {
        const month_list = [
          { name: "b_month", value: 1, title: "มกราคม", max_days: 31 },
          { name: "b_month", value: 2, title: "กุมภาพันธ์", max_days: 29 },
          { name: "b_month", value: 3, title: "มีนาคม", max_days: 31 },
          { name: "b_month", value: 4, title: "เมษายน", max_days: 30 },
          { name: "b_month", value: 5, title: "พฤษภาคม", max_days: 31 },
          { name: "b_month", value: 6, title: "มิถุนายน", max_days: 30 },
          { name: "b_month", value: 7, title: "กรกฎาคม", max_days: 31 },
          { name: "b_month", value: 8, title: "สิงหาคม", max_days: 31 },
          { name: "b_month", value: 9, title: "กันยายน", max_days: 30 },
          { name: "b_month", value: 10, title: "ตุลาคม", max_days: 31 },
          { name: "b_month", value: 11, title: "พฤศจิกายน", max_days: 31 },
          { name: "b_month", value: 12, title: "ธันวาคม", max_days: 31 },
        ];
        const d = moment(new Date(date));

        const month_text = month_list.find(
          (x) => x.value === d.month() + 1
        ).title;
        return (
          "วันที่",
          d.date() +
            " เดือน " +
            month_text +
            " พ.ศ. " +
            (Number(d.year()) + Number(543))
        );
      } else {
        return "";
      }
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>

<style>
.h-100 {
  /* แก้หน้า 2 เปล่า */
  height: auto !important;
}
@font-face {
  /* font */
  font-family: "MyCustomFont";
  src: url("../../../assets/fonts/Sarabun-Regular.ttf") format("truetype"); /* Update the path and format */
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "MyCustomFont", sans-serif !important;
  width: 210mm;
  /* height: 297mm; */
  color: black !important;
  margin: 0 auto;
  background-color: white !important;
}

@media print {
  body {
    font-family: "MyCustomFont", sans-serif !important;
    width: 210mm;
    height: 297mm;
    padding-top: 0mm !important;
    padding-top: 10mm !important;
    padding-left: 10mm !important;
    padding-right: 10mm !important;
    color: black !important;
    background-color: white;
  }
  pre {
    background: none;
    color: black;
    border: none;
  }

  .page-break {
    margin-top: 12mm;
    page-break-before: always;
  }
}
h4 {
  color: black !important;
}
.accessories_include {
  color: black !important;
  font-size: 12px;
}

.my-table {
  width: 100%;
  border-collapse: collapse;
}

.my-table th,
.my-table td {
  padding: 10px 10px 10px 10px; /* shorthand for padding */
  border: 1px solid rgb(198, 198, 198); /* applies border to all sides */
  vertical-align: top;
}

.my-table th:first-child,
.my-table td:first-child {
  border-left: 1px solid rgb(198, 198, 198);
}

.my-table th:last-child,
.my-table td:last-child {
  border-right: 1px solid rgb(198, 198, 198);
}
.card-body {
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
}
.card {
  box-shadow: none;
}

.serial-number {
  width: 20px !important;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>
